// TEMPLATES/JSX/SECTION STYLES

.adt-section {
  height: 100%;
  position: relative;
  width: 100%;

  > .ada-image {
    position: absolute;
    width: 100%;
    z-index: 0; }

  > .ada-container {
    @include flex(row);
    @include respond-to(schmedium) {
      padding-bottom: 0; }

    height: 100%;
    padding: px-to-em(40) 0;
    position: relative;
    z-index: 1; } }
