// -----------------------------------------------------------------------------
// Styles entry point
// @author Lexus Drumgold
// -----------------------------------------------------------------------------

// Charset
@charset 'utf-8';

// Directories
@import "abstracts/abstracts";
@import "base/base";
@import "components/components";

// Styles

#root {
  @include flex(column);
  @include transition(all, .375s);
  @include respond-to(schmedium) {
    background-image: none !important; }


  background-color: $dark;
  height: 100vh;
  width: 100vw;

  > .ada-container {
    display: flex;
    flex-direction: row;
    height: 100%; }

  &.loading {
    @include flex(row, center, center); }

  &.section01 {
    @include set-bkg-img('section01.jpg', center top); }

  &.section02 {
    @include set-bkg-img('section02.jpg'); }

  &.section03 {
    @include set-bkg-img('section03.jpg'); }

  &.section04 {
    @include set-bkg-img('section04.jpg'); }

  &.section05 {
    @include set-bkg-img('section05.jpg'); }

  &.section06 {
    @include set-bkg-img('section06.jpg'); } }
