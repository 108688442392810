@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,400i,500,500i,600,600i,700,700i);
/* FONTS */
/* GENERAL STYLES - BASE/COMMON */
* {
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s; }

*,
html {
  box-sizing: border-box; }

html {
  overflow-x: hidden; }

body {
  color: #F4F4F4;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica, Helvetica Neue;
  margin: 0 !important;
  overflow: hidden; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 700; }

a:hover, a:active, a:focus,
button:hover,
button:active,
button:focus {
  cursor: pointer;
  font-weight: 600; }

a {
  text-decoration: none; }
  a.active {
    font-weight: 600; }

ul {
  margin: 0;
  padding: 0; }

li {
  list-style: none; }

button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0; }

input {
  background-color: transparent;
  border: none;
  font-size: 0.9375em;
  outline: none;
  padding: 0; }

/* HELPER CLASSES - BASE/HELPERS */
.ada-container {
  margin: 0 auto;
  max-width: 95%;
  width: 100%; }

.adm-navigation, #lightbox {
  height: 100vh;
  opacity: 0;
  position: absolute;
  width: 100vw;
  z-index: -1; }
  .open.adm-navigation, .open#lightbox {
    background-color: #000;
    opacity: 1;
    z-index: 10; }

.ada-button, .ada-navigation-item {
  background-color: #E51D37;
  border-radius: 50%;
  color: #F4F4F4;
  font-size: 0.75em;
  height: 3.125em;
  text-align: center;
  width: 3.125em; }
  .ada-button:hover, .ada-navigation-item:hover {
    background-color: #d11830; }
  .ada-button:hover, .ada-navigation-item:hover, .ada-button:active, .ada-navigation-item:active, .ada-button:focus, .ada-navigation-item:focus {
    border: 0.3125em solid #F4F4F4; }
  @media all and (max-width: 30em) {
    .ada-button, .ada-navigation-item {
      height: 2.8125em;
      width: 2.8125em; } }
  .active.ada-button, .active.ada-navigation-item {
    border: 0.3125em solid #F4F4F4; }

.ada-heading {
  margin: 0 0 0.3125em 0;
  text-transform: uppercase; }

h1.ada-heading {
  font-size: 2.25em;
  margin: 0; }
  @media all and (max-width: 90em) {
    h1.ada-heading {
      font-size: 1.5em; } }
  @media all and (max-width: 75em) {
    h1.ada-heading {
      font-size: 1.375em; } }
  @media all and (max-width: 61.25em) {
    h1.ada-heading {
      font-size: 1.125em; } }
  h1.ada-heading span {
    color: #E51D37;
    font-weight: 600; }
    h1.ada-heading span span:first-of-type {
      color: lightgrey;
      font-weight: 200; }
    h1.ada-heading span span:last-of-type {
      color: #F4F4F4;
      font-size: 1rem;
      font-weight: 300; }

.ada-image {
  -webkit-transition: all 0.375s ease-in-out 0s;
  transition: all 0.375s ease-in-out 0s; }

.ada-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #F4F4F4;
  width: 100%; }
  .ada-loading > .ada-icon {
    font-size: 1.875em; }
  .ada-loading > .ada-text {
    font-size: 0.9375em;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase; }

.ada-navigation-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #E51D37; }
  .ada-navigation-item:hover {
    background-color: #d11830; }

.ada-request-manager {
  height: 100%;
  width: 100%; }
  .ada-request-manager > .ada-loading {
    height: 100%; }

.ada-text {
  font-size: 0.875em;
  font-weight: 300; }
  @media all and (max-width: 48em) {
    .ada-text {
      font-size: 0.8125em; } }
  .ada-text > strong {
    font-weight: 600; }

.adm-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.375s ease 0s;
  transition: all 0.375s ease 0s; }
  .adm-navigation > .ada-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 60%; }
  @media all and (max-width: 30em) {
    .links {
      width: 100%; } }

.ado-card > .ada-container {
  height: 100%;
  max-width: 92.5%; }

.ado-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.725);
  height: 100%;
  position: relative;
  width: 70%; }
  @media all and (max-width: 90em) {
    .ado-card {
      width: 50%; } }
  @media all and (max-width: 75em) {
    .ado-card {
      width: 60%; } }
  @media all and (max-width: 61.25em) {
    .ado-card {
      width: 70%; } }
  @media all and (max-width: 48em) {
    .ado-card {
      padding-top: 0em;
      width: 100%; } }
  .ado-card > .ada-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow-y: scroll; }
    @media all and (max-width: 48em) {
      .ado-card > .ada-container {
        max-width: 100%; } }
    .ado-card > .ada-container::-webkit-scrollbar {
      background-color: #E51D37;
      border-radius: 2em;
      width: 0.3125em; }
    .ado-card > .ada-container > .ada-text {
      margin-top: 0;
      width: 98%; }
      .ado-card > .ada-container > .ada-text:last-of-type {
        padding-bottom: 0.3125em; }

.card-header,
.card-footer {
  padding: 1.875em 0;
  width: 100%; }
  .card-header > .ada-container,
  .card-footer > .ada-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-width: 92.5%; }

@media all and (max-width: 48em) {
  .card-header {
    background-color: #000;
    padding: 0 0 1.25em 0; }
    .card-header > .ada-container {
      max-width: 100%; } }

.card-footer {
  background-color: #000;
  border-top: 0.1875em solid #E51D37;
  bottom: 0;
  margin-top: 0.9375em; }
  .card-footer > .ada-container a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #F4F4F4;
    font-size: 0.75em;
    font-weight: 500;
    text-transform: uppercase; }
    .card-footer > .ada-container a > .ada-icon {
      font-size: 1.40625em; }
      .card-footer > .ada-container a > .ada-icon:first-of-type {
        margin-right: 0.3125em; }
      .card-footer > .ada-container a > .ada-icon:last-of-type {
        margin-left: 0.3125em; }

#section01 .ada-heading {
  font-weight: 300; }

#lightbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.ado-lightbox {
  height: auto;
  padding: 0;
  width: 95%; }
  .ado-lightbox > .ada-container {
    height: 100%; }

.lightbox-footer {
  padding: 0.625em 0;
  width: 100vw; }
  .lightbox-footer > .ada-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }

.ado-footer {
  bottom: 0;
  padding-bottom: 2.5em;
  position: fixed;
  width: 100vw;
  z-index: 15; }
  @media all and (max-width: 48em) {
    .ado-footer {
      align-self: center;
      padding-bottom: 1.40625em;
      width: 10vw; } }
  .ado-footer > .ada-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start; }
    @media all and (max-width: 48em) {
      .ado-footer > .ada-container {
        justify-content: center !important; } }

.adt-section {
  height: 100%;
  position: relative;
  width: 100%; }
  .adt-section > .ada-image {
    position: absolute;
    width: 100%;
    z-index: 0; }
  .adt-section > .ada-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    padding: 2.5em 0;
    position: relative;
    z-index: 1; }
    @media all and (max-width: 48em) {
      .adt-section > .ada-container {
        padding-bottom: 0; } }

#root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  -webkit-transition: all 0.375s ease 0s;
  transition: all 0.375s ease 0s;
  background-color: #000;
  height: 100vh;
  width: 100vw; }
  @media all and (max-width: 48em) {
    #root {
      background-image: none !important; } }
  #root > .ada-container {
    display: flex;
    flex-direction: row;
    height: 100%; }
  #root.loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
  #root.section01 {
    background-image: url(/static/media/section01.ce8928af.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover; }
  #root.section02 {
    background-image: url(/static/media/section02.805e6197.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
  #root.section03 {
    background-image: url(/static/media/section03.c999eda9.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
  #root.section04 {
    background-image: url(/static/media/section04.214db295.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
  #root.section05 {
    background-image: url(/static/media/section05.c46d8a9b.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
  #root.section06 {
    background-image: url(/static/media/section06.07101626.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }

