// ORGANISMS/JSX/LIGHTBOX STYLES

#lightbox {
  @include flex(row, center, center);
  @extend %lightbox; }

.ado-lightbox {
  height: auto;
  padding: 0;
  width: 95%;

  > .ada-container {
    height: 100%; } }

.lightbox-footer {
  padding: px-to-em(10) 0;
  width: 100vw;

  > .ada-container {
    @include flex(row, center, space-between); } }
