// ATOMS/JSX/TEXT STYLES

@import "abstracts/abstracts";

.ada-text {
  @include respond-to(schmedium) {
    font-size: px-to-em(13); }


  font: {
    size: px-to-em(14);
    weight: 300; }

  > strong {
    font-weight: 600; } }
