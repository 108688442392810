// ATOMS/JSX/LOADING STYLES

.ada-loading {
  @include flex(column, center, center);

  color: $light;
  width: 100%;

  > .ada-icon {
    font-size: px-to-em(30); }

  > .ada-text {
    font: {
      size: px-to-em(15);
      weight: 400; }
    text: {
      align: center;
      transform: uppercase; } } }
