// MOLECULES/JSX/NAVIGATION STYLES

.adm-navigation {
  @include flex(row, center, center);
  @include transition(all, .375s);

  @extend %lightbox;

  > .ada-container {
    @include flex(row, center, center); } }

.links {
  @include flex(row, center, space-between);
  @include respond-to(small) {
    width: 100%; }

  width: 60%; }
