// ATOMS/JSX/HEADING STYLES

.ada-heading {
  margin: 0 0 px-to-em(5) 0;
  text-transform: uppercase; }

h1 {
  &.ada-heading {
    @include respond-to(xlarge) {
      font-size: px-to-em(24); }


    @include respond-to(large) {
      font-size: px-to-em(22); }


    @include respond-to(medium) {
      font-size: px-to-em(18); }


    font-size: px-to-em(36);
    margin: 0;

    span {
      color: $accent;
      font-weight: 600;

      span {
        &:first-of-type {
          color: lightgrey;
          font-weight: 200; }

        &:last-of-type {
          color: #F4F4F4;
          font-size: 1rem;
          font-weight: 300; } } } } }
